import React from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, srcSetProps, sanityImageUrl, clickTrack } from '../../../utils/format'
import { PopupButton } from '@typeform/embed-react'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <p className={styles.titleSmall}>{header.smallTitle}</p>
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
          <ul className={styles.list}>
            {header.benefits &&
              header.benefits.map((item, index) => (
                <li className={styles.listItem} key={index}>
                  {prepareParagraph(item)}
                </li>
              ))}
          </ul>
          {header.buttonUrl?.includes('https://quiz/') ? (
            <PopupButton
              id={buttonUrl.replace('https://quiz/', '')}
              as="a"
              onReady={() => clickTrack(header.buttonText)}
              transitiveSearchParams={true}
              className={styles.button}
            >
              {header.buttonText}
            </PopupButton>
          ) : (
            <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
              {header.buttonText}
            </a>
          )}
        </div>
        <div className={styles.image}>
          <img {...srcSetProps(sanityImageUrl(header.image))} className={styles.imageSrc} alt={header.image?.caption} />
        </div>
      </div>
    </div>
  )
}
