import React from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, srcSetProps, sanityImageUrl, clickTrack } from '../../../utils/format'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ steps }) => {
  if (!steps || steps.isHide) return null

  return (
    <div className={styles.steps}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{steps.title}</h2>
        <p className={styles.description}>{steps.description}</p>
        <div className={styles.items}>
          {steps.items &&
            steps.items.map(item => (
              <div className={styles.item} key={item._key}>
                <div className={styles.image}>
                  <img
                    {...srcSetProps(sanityImageUrl(item.image))}
                    className={styles.imageSrc}
                    alt={item.image?.caption}
                  />
                </div>
                <div className={styles.text}>
                  <p className={styles.itemTitle}>{item.title}</p>
                  <p className={styles.itemDescription}>{prepareParagraph(item.description)}</p>
                </div>
                {item.buttonUrl?.includes('https://quiz/') ? (
                  <PopupButton
                    id={buttonUrl.replace('https://quiz/', '')}
                    as="a"
                    transitiveSearchParams={true}
                    className={styles.button}
                    onReady={() => clickTrack(item.buttonText)}
                  >
                    {item.buttonText}
                  </PopupButton>
                ) : (
                  <a href={item.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
                    {item.buttonText}
                  </a>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
